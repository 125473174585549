.markdown-list ul li p {
    list-style-type: none; /* Removes default list item styling */
    position: relative;
    margin-left: 1em; /* Adjust as needed */
}

.markdown-list ul li p::before {
    content: '- '; /* Adds dash before the paragraph */
    position: absolute;
    left: -1em; /* Adjust position to align with the list */
}

