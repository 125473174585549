.tooltip-wrapper {
  position: relative;
  display: inline-block;

  /* Inherit font properties from the parent */
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
}

.tooltip-content {
  position: absolute;
  background-color: white; /* White background */
  color: black; /* Black font color */
  padding: 20px; /* Increased padding */
  border: 1px solid black; /* Fine black border */
  border-radius: 0;
  top: 0;
  left: 100%;
  transform: translateY(-100%);
  white-space: nowrap;
  z-index: 1;
  display: none; /* Initially hidden */

  /* Inherit font properties from the tooltip-wrapper */
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
}

.tooltip-wrapper:hover .tooltip-content {
  display: block; /* Show on hover */
}

